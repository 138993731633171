var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('TTableAdvance',{attrs:{"items":_vm.list,"fields":_vm.fields,"store":"warehouse.container_types","resource":"","creatable":"","deletable":""},on:{"click-clear-filter":_vm.clearFilter,"click-create":function($event){_vm.showModalCreate = true}},scopedSlots:_vm._u([{key:"append-actions",fn:function(ref){
var item = ref.item;
return [_c('CCol',{staticClass:"px-1 text-center",attrs:{"col":"12"}},[_c('TButtonEdit',{on:{"click":function($event){return _vm.editItem(item)}}})],1)]}},{key:"name",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessage',{attrs:{"content":item.name,"noTranslate":""}})],1)]}},{key:"max_volume",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageVolume',{staticClass:"font-weight-bold",attrs:{"value":item.max_volume}})],1)]}},{key:"max_weight",fn:function(ref){
var item = ref.item;
return [_c('td',[_c('TMessageNumber',{staticClass:"font-weight-bold",attrs:{"value":item.max_weight,"suffix":"kg"}})],1)]}},{key:"id-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Id","value":_vm.filter.id},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "id", $event)},_vm.filterChange]}})]},proxy:true},{key:"name-filter",fn:function(){return [_c('TInputText',{attrs:{"placeholder":"Name","value":_vm.filter.name},on:{"update:value":[function($event){return _vm.$set(_vm.filter, "name", $event)},_vm.filterChange]}})]},proxy:true}])}),_c('ContainerTypeModal',{attrs:{"show":_vm.showModalCreate},on:{"update:show":function($event){_vm.showModalCreate=$event}}}),_c('ContainerTypeModal',{attrs:{"show":_vm.showModalEdit,"detail":_vm.detail},on:{"update:show":function($event){_vm.showModalEdit=$event}}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }